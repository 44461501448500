import { Button } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import React from 'react';
import ReactModal from 'react-modal';

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    zIndex:2
  },
  content: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    background: '#fff',
    boxShadow: '0px 20px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '30px',
    border: 'none',
    inset: '50% auto auto 50%',
    padding: '25px 44px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
const ButtonsDiv = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '50px',
});
function PopupWarning({ onPopup, handleClosePopup, onDelete }) {
  return (
    <ReactModal
      isOpen={!!onPopup}
      contentLabel="sent request"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      onRequestClose={handleClosePopup}
      style={modalStyle}
    >
      <h2>Are you sure?</h2>     
      <ButtonsDiv>
        <Button onClick={() => { onDelete(); handleClosePopup(); }}>Delete</Button>
        <Button onClick={handleClosePopup}>Cancel</Button>
      </ButtonsDiv>

    </ReactModal>
  );
}

export default PopupWarning;
