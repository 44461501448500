import React from 'react';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ListArticles from './pages/ListArticles';
import {
  listCorporatePosts,
  getCorporatePost,
  listWeddingsPosts,
  getWeddingsPost,
  listPrivatePosts,
  getPrivatePost,
  listNonProfitPosts,
  getNonProfitPost,
} from './graphql/queries';
import {
  createCorporatePost,
  createNonProfitPost,
  createPrivatePost,
  deleteCorporatePost,
  deleteNonProfitPost,
  deletePrivatePost,
  deleteWeddingsPost,
  updateCorporatePost,
  updateNonProfitPost,
  updatePrivatePost,
  updateWeddingsPost,
} from './graphql/mutations';
import ChangeArticle from './pages/ChangeArticle';
import Main from './pages/Main';
import Layout from './components/Layout';
import {
  getUpdatedData,
  initialFormState,
} from './utils/getUpdatedDataValues';
import Category from './pages/Category';

function App({ signOut }) {
  return (
    <Router>
      <Layout signOut={signOut}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/corporate/:id"
            element={<Category category="corporate" />}
          />
          <Route
            path="/weddings/:id"
            element={<Category category="weddings" />}
          />
          <Route
            path="/private/:id"
            element={<Category category="private" />}
          />
          <Route
            path="/nonProfit/:id"
            element={<Category category="nonProfit" />}
          />
          <Route
            path="/category/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listCorporatePosts}
                getQuery={getCorporatePost}
                createMutation={createCorporatePost}
                idName={'corporateItemPostsId'}
                listTitle="listCorporatePosts"
                createTitle="createCorporatePost"
                route="/corporate/post/"
                initialFormState={initialFormState
            }
              />
            }
          />
          <Route
            path="/category/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listWeddingsPosts}
                getQuery={getWeddingsPost}
                createMutation={createCorporatePost}
                idName={'weddingItemPostsId'}
                listTitle="listWeddingPosts"
                createTitle="createWeddingPost"
                route="/wedding/post/"
                initialFormState={initialFormState
            }
              />
            }
          />
          <Route
            path="/category/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listPrivatePosts}
                getQuery={getPrivatePost}
                createMutation={createPrivatePost}
                idName={'privateItemPostsId'}
                listTitle="listPrivatePosts"
                createTitle="createPrivatgPost"
                route="/private/post/"
                initialFormState={initialFormState
            }
              />
            }
          />
          <Route
            path="/category/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listNonProfitPosts}
                getQuery={getNonProfitPost}
                createMutation={createNonProfitPost}
                idName={'nonProfitItemPostsId'}
                listTitle="listNonProfitPosts"
                createTitle="createNonProfitPost"
                route="/nonProfit/post/"
                initialFormState={initialFormState
            }
              />
            }
          />
          <Route
            path="/corporate/post/:postID"
            element={
              <ChangeArticle
                getQuery={getCorporatePost}
                getQueryTitle={'getCorporatePost'}
                deleteMutation={deleteCorporatePost}
                deleteMutationTitle={'deleteCorporatePost'}
                getUpdatedData={getUpdatedData}
                idName={'corporateItemPostsId'}
                updateMutation={updateCorporatePost}
                route="/category/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/wedding/post/:postID"
            element={
              <ChangeArticle
                getQuery={getWeddingsPost}
                getQueryTitle={'getWeddingPost'}
                deleteMutation={deleteWeddingsPost}
                deleteMutationTitle={'deleteWeddingsPost'}
                getUpdatedData={getUpdatedData}
                idName={'weddingsItemPostsId'}
                updateMutation={updateWeddingsPost}
                route="/category/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/private/post/:postID"
            element={
              <ChangeArticle
                getQuery={getPrivatePost}
                getQueryTitle={'getPrivatePost'}
                deleteMutation={deletePrivatePost}
                deleteMutationTitle={'deletePrivatePost'}
                getUpdatedData={getUpdatedData}
                idName={'privatesItemPostsId'}
                updateMutation={updatePrivatePost}
                route="/category/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/nonProfit/post/:postID"
            element={
              <ChangeArticle
                getQuery={getNonProfitPost}
                getQueryTitle={'getNonProfitPost'}
                deleteMutation={deleteNonProfitPost}
                deleteMutationTitle={'deleteNonProfitPost'}
                getUpdatedData={getUpdatedData}
                idName={'nonProfitItemPostsId'}
                updateMutation={updateNonProfitPost}
                route="/category/:id/blog/:blogId"
              />
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default withAuthenticator(App);
