import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import styled from '@emotion/styled';
import { Button, Heading, Text, Badge } from '@aws-amplify/ui-react';
import PopupCreatePost from '../components/PopupCreatePost';
import { getImageUrl } from '../utils';

const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
`;
const TableHeader = styled('div')`
  display: grid;
  grid-template-columns: 1fr 150px;
  align-items: center;
  justify-items: center;
`;
const PostsConatiner = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;
const PostConatiner = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px;
  ${'' /* height: 100px; */}
  img {
    max-width: 100px;
  }
`;
const DivFlexLeft = styled('div')`
  width: 100px;
  padding: 5px;
  display: grid;
  place-content: center;
`;
const DivFlexRight = styled('div')`
  width: 200px;
  padding: 5px;
  display: grid;
  place-content: center;
  gap: 20%;
`;
const DivFlexCenter = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
`;

function ListArticles({
  listQuery,
  getQuery,
  createMutation,
  idName,
  listTitle,
  createTitle,
  initialFormState,
  route = '/blog/post/',
}) {
  const [blog, setBlog] = useState(null);
  const [posts, setPosts] = useState([]);
  const { blogId } = useParams();
  const [onPopupTitle, setOnPopupTitle] = useState(false);
  const navigate = useNavigate();
  async function fetchPosts(id) {
    const apiData = await API.graphql({
      query: listQuery,
      variables: { filter: { [idName]: { eq: blogId } } },
    });


    const postsFromAPI = apiData.data[listTitle].items;
    await Promise.all(
      postsFromAPI.map(async post => {
        if (post.featuredImage) {
          const image = getImageUrl(post.featuredImage);
          post.featuredImageUrl = image;
        }
        if (post.media.length > 0) {
          const images = post.media.map(getImageUrl);
          post.mediaUrls = images;
        }
        return post;
      }),
    );
    setPosts(
      postsFromAPI.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      ),
    );
  }

  useEffect(() => {
    async function fetchBlog() {
      const { data } = await API.graphql({
        query: getQuery,
        variables: {
          id: blogId,
        },
      });
      setBlog(data[getQuery]);
    }
    if (blogId) {
      fetchBlog();
      fetchPosts();
    }
  }, [blogId]);
  async function createPostTitle(title) {
    const initialState = initialFormState;
    if (!title) return;
    const { data } = await API.graphql({
      query: createMutation,
      variables: {
        input: {
          ...initialState,
          title,
          [idName]: blogId,
          visibility: true,
          publishAt: initialState?.publishAt,
        },
      },
    });

    const newPost = data?.[createTitle];
    navigate(`${route}${newPost.id}`);
  }

  return (
    <Container>
      <PostsConatiner>
        <TableHeader>
          <Heading level={2}>{'List of articles'}</Heading>
          <Button variation="primary" onClick={() => setOnPopupTitle(true)}>
            Add
          </Button>
        </TableHeader>
        <PopupCreatePost
          onPopup={onPopupTitle}
          handleClosePopup={() => {
            setOnPopupTitle(false);
          }}
          onCreate={createPostTitle}
        />
        {posts.map(
          post =>
            !post._deleted && (
              <PostConatiner key={post.id}>
                <DivFlexLeft>
                  {post.featuredImageUrl && (
                    <img
                      src={post.featuredImageUrl}
                      style={{ width: 400 }}
                      alt={post.title}
                    />
                  )}
                </DivFlexLeft>
                <DivFlexCenter>
                  <Heading level={5}>
                    {post.title}
                    <span>{!post.visibility && <Badge>Hidden</Badge>}</span>
                  </Heading>
                  <Text>
                    <span>
                      <b>Author:</b>
                      &nbsp;
                    </span>
                    {post.author}
                  </Text>
                  <Text>
                    <span>
                      <b>Created at:</b>
                      &nbsp;
                    </span>
                    {post.createdAt}
                  </Text>
                  <Text>
                    <span>
                      <b>Tags:</b>
                      &nbsp;
                    </span>
                    {post.categories?.map(cat => (
                      <Badge key={`${post.id}-${cat}`}>{cat}</Badge>
                    ))}
                  </Text>
                </DivFlexCenter>
                <DivFlexRight>
                  <Link
                    to={`${route}${post.id}`}
                    className="amplify-button amplify-button--link"
                  >
                    Edit
                  </Link>
                  {/* <Link
                to={`/post/${post.id}`}
                target='_blank'
                className='amplify-button amplify-button--link'
              >
                Preview
              </Link> */}
                </DivFlexRight>
              </PostConatiner>
            ),
        )}
      </PostsConatiner>
    </Container>
  );
}

export default ListArticles;
