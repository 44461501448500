import { Button, Heading } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  position: relative;
  a {
    text-decoration: none;
  }
  img {
    width: 100%;
    max-width: 400px;
    margin: 20px;
  }
`;
const ContextMenu = styled.div`
  position: absolute;
  top: 15px;
  right: 35px;
  max-width: 200px;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContextMenuButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
`;
function StartCards({ content, setOnPopup, href }) {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(null);

  const toggleContextMenu = id => {
    if (id === isContextMenuOpen) {
      return setIsContextMenuOpen(null);
    }
    setIsContextMenuOpen(id);
  };

  // const closeContextMenu = () => {
  //   setIsContextMenuOpen(false);
  // };

  // useEffect(() => {
  //   const handleDocumentClick = (event) => {
  //     if (
  //       isContextMenuOpen &&
  //       ref.current &&
  //       ref2.current &&
  //       !ref.current.contains(event.target) &&
  //       !ref2.current.contains(event.target)
  //     ) {
  //       closeContextMenu();
  //     }
  //   };

  //   document.addEventListener('click', handleDocumentClick);

  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick);
  //   };
  // }, [isContextMenuOpen]);
  return (
    <>
      {content.map(
        item =>
          !item._deleted && (
            <Container key={item.id || item.name}>
              <Link to={`/${href}/${item.id}`}>
                <Heading level={2}>{item.name}</Heading>
                {item.imageUrl && <img src={item.imageUrl} alt={item.name} />}
              </Link>
              <ContextMenuButton onClick={() => toggleContextMenu(item.id)}>
                ***
              </ContextMenuButton>
              {!!isContextMenuOpen && isContextMenuOpen == item.id && (
                <ContextMenu>
                  <Button
                    onClick={() => {
                      console.log(item.id, 'ID');
                      setOnPopup({ id: item.id, version: item._version });
                    }}
                    size="small"
                    color="red"
                    variation="link"
                  >
                    Delete
                  </Button>
                </ContextMenu>
              )}
            </Container>
          ),
      )}
    </>
  );
}

export default StartCards;
