/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWeddings = /* GraphQL */ `
  mutation CreateWeddings(
    $input: CreateWeddingsInput!
    $condition: ModelWeddingsConditionInput
  ) {
    createWeddings(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeddings = /* GraphQL */ `
  mutation UpdateWeddings(
    $input: UpdateWeddingsInput!
    $condition: ModelWeddingsConditionInput
  ) {
    updateWeddings(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeddings = /* GraphQL */ `
  mutation DeleteWeddings(
    $input: DeleteWeddingsInput!
    $condition: ModelWeddingsConditionInput
  ) {
    deleteWeddings(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeddingsItem = /* GraphQL */ `
  mutation CreateWeddingsItem(
    $input: CreateWeddingsItemInput!
    $condition: ModelWeddingsItemConditionInput
  ) {
    createWeddingsItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      weddingsItemsId
      __typename
    }
  }
`;
export const updateWeddingsItem = /* GraphQL */ `
  mutation UpdateWeddingsItem(
    $input: UpdateWeddingsItemInput!
    $condition: ModelWeddingsItemConditionInput
  ) {
    updateWeddingsItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      weddingsItemsId
      __typename
    }
  }
`;
export const deleteWeddingsItem = /* GraphQL */ `
  mutation DeleteWeddingsItem(
    $input: DeleteWeddingsItemInput!
    $condition: ModelWeddingsItemConditionInput
  ) {
    deleteWeddingsItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      weddingsItemsId
      __typename
    }
  }
`;
export const createAuthor = /* GraphQL */ `
  mutation CreateAuthor(
    $input: CreateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    createAuthor(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuthor = /* GraphQL */ `
  mutation UpdateAuthor(
    $input: UpdateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    updateAuthor(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuthor = /* GraphQL */ `
  mutation DeleteAuthor(
    $input: DeleteAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    deleteAuthor(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeddingsPost = /* GraphQL */ `
  mutation CreateWeddingsPost(
    $input: CreateWeddingsPostInput!
    $condition: ModelWeddingsPostConditionInput
  ) {
    createWeddingsPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        weddingsItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      weddingsItemPostsId
      __typename
    }
  }
`;
export const updateWeddingsPost = /* GraphQL */ `
  mutation UpdateWeddingsPost(
    $input: UpdateWeddingsPostInput!
    $condition: ModelWeddingsPostConditionInput
  ) {
    updateWeddingsPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        weddingsItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      weddingsItemPostsId
      __typename
    }
  }
`;
export const deleteWeddingsPost = /* GraphQL */ `
  mutation DeleteWeddingsPost(
    $input: DeleteWeddingsPostInput!
    $condition: ModelWeddingsPostConditionInput
  ) {
    deleteWeddingsPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        weddingsItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      weddingsItemPostsId
      __typename
    }
  }
`;
export const createCorporate = /* GraphQL */ `
  mutation CreateCorporate(
    $input: CreateCorporateInput!
    $condition: ModelCorporateConditionInput
  ) {
    createCorporate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCorporate = /* GraphQL */ `
  mutation UpdateCorporate(
    $input: UpdateCorporateInput!
    $condition: ModelCorporateConditionInput
  ) {
    updateCorporate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCorporate = /* GraphQL */ `
  mutation DeleteCorporate(
    $input: DeleteCorporateInput!
    $condition: ModelCorporateConditionInput
  ) {
    deleteCorporate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCorporateItem = /* GraphQL */ `
  mutation CreateCorporateItem(
    $input: CreateCorporateItemInput!
    $condition: ModelCorporateItemConditionInput
  ) {
    createCorporateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      corporateItemsId
      __typename
    }
  }
`;
export const updateCorporateItem = /* GraphQL */ `
  mutation UpdateCorporateItem(
    $input: UpdateCorporateItemInput!
    $condition: ModelCorporateItemConditionInput
  ) {
    updateCorporateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      corporateItemsId
      __typename
    }
  }
`;
export const deleteCorporateItem = /* GraphQL */ `
  mutation DeleteCorporateItem(
    $input: DeleteCorporateItemInput!
    $condition: ModelCorporateItemConditionInput
  ) {
    deleteCorporateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      corporateItemsId
      __typename
    }
  }
`;
export const createCorporatePost = /* GraphQL */ `
  mutation CreateCorporatePost(
    $input: CreateCorporatePostInput!
    $condition: ModelCorporatePostConditionInput
  ) {
    createCorporatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        corporateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      location
      problem
      createdAt
      updatedAt
      corporateItemPostsId
      __typename
    }
  }
`;
export const updateCorporatePost = /* GraphQL */ `
  mutation UpdateCorporatePost(
    $input: UpdateCorporatePostInput!
    $condition: ModelCorporatePostConditionInput
  ) {
    updateCorporatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        corporateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      location
      problem
      createdAt
      updatedAt
      corporateItemPostsId
      __typename
    }
  }
`;
export const deleteCorporatePost = /* GraphQL */ `
  mutation DeleteCorporatePost(
    $input: DeleteCorporatePostInput!
    $condition: ModelCorporatePostConditionInput
  ) {
    deleteCorporatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        corporateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      location
      problem
      createdAt
      updatedAt
      corporateItemPostsId
      __typename
    }
  }
`;
export const createPrivate = /* GraphQL */ `
  mutation CreatePrivate(
    $input: CreatePrivateInput!
    $condition: ModelPrivateConditionInput
  ) {
    createPrivate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePrivate = /* GraphQL */ `
  mutation UpdatePrivate(
    $input: UpdatePrivateInput!
    $condition: ModelPrivateConditionInput
  ) {
    updatePrivate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePrivate = /* GraphQL */ `
  mutation DeletePrivate(
    $input: DeletePrivateInput!
    $condition: ModelPrivateConditionInput
  ) {
    deletePrivate(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPrivateItem = /* GraphQL */ `
  mutation CreatePrivateItem(
    $input: CreatePrivateItemInput!
    $condition: ModelPrivateItemConditionInput
  ) {
    createPrivateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      privateItemsId
      __typename
    }
  }
`;
export const updatePrivateItem = /* GraphQL */ `
  mutation UpdatePrivateItem(
    $input: UpdatePrivateItemInput!
    $condition: ModelPrivateItemConditionInput
  ) {
    updatePrivateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      privateItemsId
      __typename
    }
  }
`;
export const deletePrivateItem = /* GraphQL */ `
  mutation DeletePrivateItem(
    $input: DeletePrivateItemInput!
    $condition: ModelPrivateItemConditionInput
  ) {
    deletePrivateItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      privateItemsId
      __typename
    }
  }
`;
export const createPrivatePost = /* GraphQL */ `
  mutation CreatePrivatePost(
    $input: CreatePrivatePostInput!
    $condition: ModelPrivatePostConditionInput
  ) {
    createPrivatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        privateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      privateItemPostsId
      __typename
    }
  }
`;
export const updatePrivatePost = /* GraphQL */ `
  mutation UpdatePrivatePost(
    $input: UpdatePrivatePostInput!
    $condition: ModelPrivatePostConditionInput
  ) {
    updatePrivatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        privateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      privateItemPostsId
      __typename
    }
  }
`;
export const deletePrivatePost = /* GraphQL */ `
  mutation DeletePrivatePost(
    $input: DeletePrivatePostInput!
    $condition: ModelPrivatePostConditionInput
  ) {
    deletePrivatePost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        privateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      privateItemPostsId
      __typename
    }
  }
`;
export const createNonProfit = /* GraphQL */ `
  mutation CreateNonProfit(
    $input: CreateNonProfitInput!
    $condition: ModelNonProfitConditionInput
  ) {
    createNonProfit(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNonProfit = /* GraphQL */ `
  mutation UpdateNonProfit(
    $input: UpdateNonProfitInput!
    $condition: ModelNonProfitConditionInput
  ) {
    updateNonProfit(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNonProfit = /* GraphQL */ `
  mutation DeleteNonProfit(
    $input: DeleteNonProfitInput!
    $condition: ModelNonProfitConditionInput
  ) {
    deleteNonProfit(input: $input, condition: $condition) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNonProfitItem = /* GraphQL */ `
  mutation CreateNonProfitItem(
    $input: CreateNonProfitItemInput!
    $condition: ModelNonProfitItemConditionInput
  ) {
    createNonProfitItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      nonProfitItemsId
      __typename
    }
  }
`;
export const updateNonProfitItem = /* GraphQL */ `
  mutation UpdateNonProfitItem(
    $input: UpdateNonProfitItemInput!
    $condition: ModelNonProfitItemConditionInput
  ) {
    updateNonProfitItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      nonProfitItemsId
      __typename
    }
  }
`;
export const deleteNonProfitItem = /* GraphQL */ `
  mutation DeleteNonProfitItem(
    $input: DeleteNonProfitItemInput!
    $condition: ModelNonProfitItemConditionInput
  ) {
    deleteNonProfitItem(input: $input, condition: $condition) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      nonProfitItemsId
      __typename
    }
  }
`;
export const createNonProfitPost = /* GraphQL */ `
  mutation CreateNonProfitPost(
    $input: CreateNonProfitPostInput!
    $condition: ModelNonProfitPostConditionInput
  ) {
    createNonProfitPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        nonProfitItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      nonProfitItemPostsId
      __typename
    }
  }
`;
export const updateNonProfitPost = /* GraphQL */ `
  mutation UpdateNonProfitPost(
    $input: UpdateNonProfitPostInput!
    $condition: ModelNonProfitPostConditionInput
  ) {
    updateNonProfitPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        nonProfitItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      nonProfitItemPostsId
      __typename
    }
  }
`;
export const deleteNonProfitPost = /* GraphQL */ `
  mutation DeleteNonProfitPost(
    $input: DeleteNonProfitPostInput!
    $condition: ModelNonProfitPostConditionInput
  ) {
    deleteNonProfitPost(input: $input, condition: $condition) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        nonProfitItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      nonProfitItemPostsId
      __typename
    }
  }
`;
