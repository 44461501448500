import React from 'react';
import { Formik, Field } from 'formik';
import DatePicker from 'react-datepicker';
import styled from '@emotion/styled';
import { TagsInput } from 'react-tag-input-component';
import {
  Button,
  TextField,
  TextAreaField,
  CheckboxField,
  Text,
  RadioGroupField,
  Radio,
} from '@aws-amplify/ui-react';
import TextEditor from '../TextEditor';
import 'react-datepicker/dist/react-datepicker.css';
import { getImageUrl } from '../../utils';
import { useLocation } from 'react-router-dom';
import FaqBlock from '../FaqBlock';
import { API } from 'aws-amplify';
import { listAuthors } from '../../graphql/queries';

const ContainerForm = styled('form')`
  display: grid;
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  column-gap: 10px;
  row-gap: 50px;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'sb ed'
    'faq faq'
    'btn btn';
`;
const FormBar = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px;
  gap: 10px;
  grid-area: sb;
  & > div,
  textarea {
    width: -webkit-fill-available;
  }
`;
const EditorContainer = styled('div')`
  grid-area: ed;
`;
const MediaButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;
const SubmitButton = styled.div`
  grid-area: btn;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MediaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  raw-gat: 10px;
`;
const FaqContainer = styled.div`
  grid-area: faq;
`;
const Avatars = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;
const kebabCase = string =>
  string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

function PostForm({
  onSubmit,
  onFeaturedImageChange,
  id,
  initialValues,
  onImageChange,
  onImageDelete,
}) {
  const location = useLocation();

  const [tags, setTags] = React.useState(initialValues.categories || []);
  const [problem, setProblem] = React.useState(initialValues.problem || []);
  const [faqs, setFaqs] = React.useState(initialValues.faq || []);
  const [authors, setAuthors] = React.useState([]);
  const [currentAuthor, setCurrentAuthor] = React.useState(
    initialValues.author,
  );
  const [currentAvatar, setCurrentAvatar] = React.useState(
    initialValues.avatar,
  );
  const type = location.pathname.split('/')[1];

  const content = {
    news: 'articlecontent',
    blog: 'maincontent',
    interviews: 'interviewcontent',
    guides: 'guidecontent',
  };
  async function fetchAuthors() {
    const apiData = await API.graphql({ query: listAuthors });
    const authorsFromAPI = apiData.data.listAuthors.items.filter(
      item => !item._deleted,
    );
    await Promise.all(
      authorsFromAPI.map(async item => {
        if (item.image) {
          const image = getImageUrl(item.image);
          item.imageUrl = image;
        }
        return item;
      }),
    );
    setAuthors(authorsFromAPI);

    if (!initialValues.author) {
      setCurrentAuthor(authorsFromAPI[0]?.name);
    }
    if (!initialValues.avatar) {
      setCurrentAvatar(authorsFromAPI[0]?.image);
    }
  }

  React.useEffect(() => {
    fetchAuthors();
  }, []);

  const [featureImgSrc, setNewFeatureImgSrc] = React.useState(
    initialValues.featuredImageUrl,
  );

  const handleFeaturedImageChange = (event, formProps) => {
    const file = event.currentTarget.files[0];
    formProps.setFieldValue('featuredImage', file.name);
    onFeaturedImageChange(event);
    setNewFeatureImgSrc(URL.createObjectURL(file));
  };

  const handleMediaChange = e => {
    onImageChange(e);
  };
  const onChangeProblem = (e, func, value) => {
    const currentValue = e.target.value;
    if (value.includes(currentValue) || e.target.value === '') return;
    func([...value, currentValue]);
    e.target.value = null;
  };

  const onAuthorChange = e => {
    setCurrentAuthor(e.target.value);
    const avatar = authors.filter(item => item.name === e.target.value);
    setCurrentAvatar(avatar[0].image);
  };
  const onChangeTag = e => {
    const arr = e.map(x => x.split(',')).flat();
    setTags(arr.map(x => x.trim()));
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          let changedHandle;
          if (values.handle === '') {
            changedHandle = kebabCase(values.title);
          } else {
            changedHandle = kebabCase(values.handle);
          }
          const changeValues = {
            ...values,
            handle: changedHandle,
            categories: tags.filter(x => x !== ''),

            faq: faqs,
            problem: problem.filter(x => x !== ''),
            author: currentAuthor,
            avatar: currentAvatar,
          };
          onSubmit(changeValues, id);
          resetForm();
        }}
      >
        {props => {
          return (
            <ContainerForm onSubmit={props.handleSubmit}>
              <FormBar>
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values?.title}
                  name="title"
                  placeholder="Post title"
                  isRequired
                  label="Title"
                  textAlign={'start'}
                />

                <TextAreaField
                  name="exerpt"
                  onChange={props.handleChange}
                  value={props.values.exerpt}
                  placeholder="Brief summary  of the post"
                  label="Summary "
                  isRequired
                  textAlign={'start'}
                />

                <label htmlFor="categories">Categories (Tags)</label>
                <TagsInput
                  value={tags}
                  onChange={onChangeTag}
                  onBlur={e => onChangeProblem(e, setTags, tags)}
                  name="categories"
                  placeHolder="categories (tags)"
                  id="categories"
                  separators={['Enter', ',']}
                />
                <label htmlFor="problem">Type of problem</label>
                <TagsInput
                  value={problem}
                  onChange={setProblem}
                  onBlur={e => onChangeProblem(e, setProblem, problem)}
                  name="problem"
                  placeHolder="Type of problem"
                  id="problem"
                />
                {/* <TextField
                type="number"
                onChange={props.handleChange}
                value={props.values.duration}
                name="duration"
                placeholder="Duration (in minutes)"
                label="Duration (in minutes):"
              /> */}
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.location}
                  name="location"
                  placeholder="Location"
                  label="Location"
                  textAlign={'start'}
                />

                <RadioGroupField
                  label="Choose author"
                  name="author"
                  onChange={e => onAuthorChange(e)}
                  defaultValue={currentAuthor || authors[0]?.name}
                >
                  {authors.map(item => (
                    <Radio value={item.name} key={item.id}>
                      {' '}
                      <Avatars>
                        <img src={item.imageUrl} alt={item.name} />
                        <div>{item.name}</div>
                      </Avatars>
                    </Radio>
                  ))}
                </RadioGroupField>

                <Text>SEO BLOCK</Text>
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.seoTitle}
                  name="seoTitle"
                  placeholder="SEO title"
                  label="SEO title"
                  textAlign={'start'}
                />
                <TextAreaField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.seoDescription}
                  name="seoDescription"
                  placeholder="SEO description"
                  label="SEO description"
                  textAlign={'start'}
                />
                <TextField
                  name="handle"
                  onChange={props.handleChange}
                  value={props.values.handle}
                  placeholder="handle"
                  label="Handle (the last part of the url)"
                  textAlign={'start'}
                />
                <Field
                  type="checkbox"
                  name="visibility"
                  as={CheckboxField}
                  label={
                    props.values.visibility ? 'Post visible' : 'Post hidden'
                  }
                />
                <label htmlFor="publishAt">
                  Created/Publish at (publication date):
                </label>
                <DatePicker
                  name="publishAt"
                  value={props.values.publishAt}
                  selected={
                    (props.values.publishAt &&
                      new Date(props.values.publishAt)) ||
                    null
                  }
                  onChange={e => {
                    props.setFieldValue('publishAt', e);
                  }}
                  label="Publish at:"
                />

                <span>Post main image:</span>
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.featuredImageAlt}
                  name="featuredImageAlt"
                  placeholder="alt text"
                  label="Alt text for main image"
                  textAlign={'start'}
                />
                <TextField
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.photoCredit}
                  name="photoCredit"
                  placeholder="photo creadit image"
                  label="Photo creadit image"
                  textAlign={'start'}
                />
                {featureImgSrc && (
                  <img
                    src={featureImgSrc}
                    alt="featured"
                    width="200px"
                    height="auto"
                  />
                )}
                <input
                  id="featuredImage"
                  name="featuredImage"
                  type="file"
                  onChange={event => handleFeaturedImageChange(event, props)}
                  label="Post main image"
                />
                {id && (
                  <>
                    <span>Post media:</span>
                    <div>
                      {initialValues.media &&
                        initialValues.media.map(fileName => {
                          const imgUrl = getImageUrl(fileName);
                          const isFileInUse =
                            props.values[content[type]].includes(imgUrl);
                          return (
                            <MediaContainer key={fileName}>
                              <img
                                src={imgUrl}
                                alt="media"
                                width="200px"
                                height="auto"
                              />
                              <MediaButtons>
                                <Button
                                  onClick={() => {
                                    if (!isFileInUse) {
                                      onImageDelete(fileName);
                                    }
                                  }}
                                  type="button"
                                  disabled={isFileInUse}
                                  color="#af3030"
                                >
                                  Delete
                                </Button>
                                <Button
                                  color="var(--amplify-components-button-primary-background-color)"
                                  onClick={() => {
                                    const contentValue = `${
                                      props.values[content[type]]
                                    }<img src=${imgUrl} width="500px" height="auto" />`;
                                    props.setFieldValue(
                                      [content[type]],
                                      contentValue,
                                    );
                                  }}
                                  type="button"
                                >
                                  Add to content
                                </Button>
                              </MediaButtons>
                            </MediaContainer>
                          );
                        })}
                    </div>
                    <input
                      type="file"
                      name="media"
                      multiple
                      onChange={handleMediaChange}
                      label="Post media"
                    />
                  </>
                )}
              </FormBar>
              <EditorContainer>
                <TextEditor
                  props={props}
                  initialValue={props.values[content[type]]}
                  name={[content[type]]}
                />
              </EditorContainer>

              {(type === 'blog' || type === 'guides') && (
                <FaqContainer>
                  {' '}
                  <FaqBlock value={faqs} onChange={setFaqs} id="categories" />
                </FaqContainer>
              )}
              <SubmitButton>
                {' '}
                <Button variation="primary" size="large" type="submit">
                  Submit
                </Button>
              </SubmitButton>
            </ContainerForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default PostForm;
