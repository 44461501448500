/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWeddings = /* GraphQL */ `
  query GetWeddings($id: ID!) {
    getWeddings(id: $id) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeddings = /* GraphQL */ `
  query ListWeddings(
    $filter: ModelWeddingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeddings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeddingsItem = /* GraphQL */ `
  query GetWeddingsItem($id: ID!) {
    getWeddingsItem(id: $id) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      weddingsItemsId
      __typename
    }
  }
`;
export const listWeddingsItems = /* GraphQL */ `
  query ListWeddingsItems(
    $filter: ModelWeddingsItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeddingsItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        weddingsItemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeddingsPost = /* GraphQL */ `
  query GetWeddingsPost($id: ID!) {
    getWeddingsPost(id: $id) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        weddingsItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      weddingsItemPostsId
      __typename
    }
  }
`;
export const listWeddingsPosts = /* GraphQL */ `
  query ListWeddingsPosts(
    $filter: ModelWeddingsPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeddingsPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        photoCredit
        exerpt
        author
        avatar
        categories
        seoTitle
        seoDescription
        handle
        duration
        media
        problem
        location
        createdAt
        updatedAt
        weddingsItemPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporate = /* GraphQL */ `
  query GetCorporate($id: ID!) {
    getCorporate(id: $id) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCorporates = /* GraphQL */ `
  query ListCorporates(
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporateItem = /* GraphQL */ `
  query GetCorporateItem($id: ID!) {
    getCorporateItem(id: $id) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      corporateItemsId
      __typename
    }
  }
`;
export const listCorporateItems = /* GraphQL */ `
  query ListCorporateItems(
    $filter: ModelCorporateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporateItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        corporateItemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporatePost = /* GraphQL */ `
  query GetCorporatePost($id: ID!) {
    getCorporatePost(id: $id) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        corporateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      location
      problem
      createdAt
      updatedAt
      corporateItemPostsId
      __typename
    }
  }
`;
export const listCorporatePosts = /* GraphQL */ `
  query ListCorporatePosts(
    $filter: ModelCorporatePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporatePosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        photoCredit
        exerpt
        author
        avatar
        categories
        seoTitle
        seoDescription
        handle
        duration
        media
        location
        problem
        createdAt
        updatedAt
        corporateItemPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivate = /* GraphQL */ `
  query GetPrivate($id: ID!) {
    getPrivate(id: $id) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPrivates = /* GraphQL */ `
  query ListPrivates(
    $filter: ModelPrivateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivateItem = /* GraphQL */ `
  query GetPrivateItem($id: ID!) {
    getPrivateItem(id: $id) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      privateItemsId
      __typename
    }
  }
`;
export const listPrivateItems = /* GraphQL */ `
  query ListPrivateItems(
    $filter: ModelPrivateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        privateItemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivatePost = /* GraphQL */ `
  query GetPrivatePost($id: ID!) {
    getPrivatePost(id: $id) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        privateItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      privateItemPostsId
      __typename
    }
  }
`;
export const listPrivatePosts = /* GraphQL */ `
  query ListPrivatePosts(
    $filter: ModelPrivatePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivatePosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        photoCredit
        exerpt
        author
        avatar
        categories
        seoTitle
        seoDescription
        handle
        duration
        media
        problem
        location
        createdAt
        updatedAt
        privateItemPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNonProfit = /* GraphQL */ `
  query GetNonProfit($id: ID!) {
    getNonProfit(id: $id) {
      id
      name
      items {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNonProfits = /* GraphQL */ `
  query ListNonProfits(
    $filter: ModelNonProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonProfits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNonProfitItem = /* GraphQL */ `
  query GetNonProfitItem($id: ID!) {
    getNonProfitItem(id: $id) {
      id
      name
      posts {
        nextToken
        __typename
      }
      image
      createdAt
      updatedAt
      nonProfitItemsId
      __typename
    }
  }
`;
export const listNonProfitItems = /* GraphQL */ `
  query ListNonProfitItems(
    $filter: ModelNonProfitItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonProfitItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        nonProfitItemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNonProfitPost = /* GraphQL */ `
  query GetNonProfitPost($id: ID!) {
    getNonProfitPost(id: $id) {
      id
      title
      category {
        id
        name
        image
        createdAt
        updatedAt
        nonProfitItemsId
        __typename
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      photoCredit
      exerpt
      author
      avatar
      categories
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
        __typename
      }
      problem
      location
      createdAt
      updatedAt
      nonProfitItemPostsId
      __typename
    }
  }
`;
export const listNonProfitPosts = /* GraphQL */ `
  query ListNonProfitPosts(
    $filter: ModelNonProfitPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonProfitPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        photoCredit
        exerpt
        author
        avatar
        categories
        seoTitle
        seoDescription
        handle
        duration
        media
        problem
        location
        createdAt
        updatedAt
        nonProfitItemPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
