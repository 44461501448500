import { Button, TextField } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import React from 'react';

const CreateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
`;
function CreateCard({
  createSection,
  onChangeImage,
  formData,
  setFormData,
  sectionName,
}) {
  return (
    <CreateContainer>
      <TextField
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        placeholder={`${sectionName} name`}
        value={formData.name}
        isRequired
      />
      <div>
        <p>Main {sectionName} image</p>
        <input type='file' onChange={onChangeImage} />
      </div>

      <Button onClick={createSection} variation='primary'>
        Create {sectionName}
      </Button>
    </CreateContainer>
  );
}

export default CreateCard;
