import React, { useState, useEffect } from 'react';
import { API, Storage } from 'aws-amplify';
import { Button, TextField,  Text } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import { createAuthor, deleteAuthor } from '../../graphql/mutations';
import PopupWarning from '../../components/PopupWarning';
import { getImageUrl } from '../../utils';
import { listAuthors } from '../../graphql/queries';

/// CSS
const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
`;
const CreateContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  > button:first-of-type {
    position: absolute;
    top: 0;
    right: 0;
  }
  p {
    margin: 5px auto;
  }
`;

const AuthorsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: wrap row;
  > div {
    margin-left: 20px;
  }
`;
const Author = styled('div')`
  display: flex;
  gap: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  padding: 0 10px;
  width: 100%;
  max-width: 200px;
  a {
    text-decoration: none;
  }
  img {
    width: 54px;
    height: 54px;
    object-fit: cover;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    border: none;
  }
`;

/// JS
const initialFormState = { name: '' };

function AuthorBlock() {
  const [authors, setAuthors] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [showCreateAuthor, setShowCreateAuthor] = useState(false);
  const [onPopup, setOnPopup] = useState('');
  const handleClosePopup = () => {
    setOnPopup('');
  };

  // fetch data
  async function fetchAuthors() {
    const apiData = await API.graphql({ query: listAuthors });
    const authorsFromAPI = apiData.data.listAuthors.items;
    await Promise.all(
      authorsFromAPI.map(async (item) => {
        if (item.image) {
          const image = getImageUrl(item.image);
          item.imageUrl = image;
        }
        return item;
      })
    );
    setAuthors(apiData.data.listAuthors.items);
  }

  useEffect(() => {
    fetchAuthors();
  }, []);

  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    setImageFile(file);

    // Display a preview of the selected image
    const url = URL.createObjectURL(file);
    setImageUrl(url);
  };

  async function handleCreateAuthor() {
    if (!formData.name) return;
    await Storage.put(formData.image, imageFile);
    const { data } = await API.graphql({
      query: createAuthor,
      variables: { input: formData },
    });
    const newItem = data?.createAuthor;
    if (newItem?.image) {
      const image = getImageUrl(newItem.image);
      newItem.imageUrl = image;
    }
    setAuthors([...authors, newItem]);
    setFormData(initialFormState);
    setImageUrl('');
    setShowCreateAuthor(false);

  }

  async function handleDeleteAuthor(
    id = onPopup?.id,
    version = onPopup?.version
  ) {
    if (id && version) {
      const { data } = await API.graphql({
        query: deleteAuthor,
        variables: { input: { id, _version: version } },
      });
      if (data?.deleteAuthor?.id) {
        const newBlogArray = authors.filter(
          (author) => author.id !== data.deleteAuthor.id
        );
        setAuthors(newBlogArray);
      }
    }
  }


  return (
    <Container>
      <AuthorsContainer>
        <Button size='small' onClick={() => setShowCreateAuthor(true)}>
          {' '}
          Add author
        </Button>
        {authors.map(
          (item) =>
            !item._deleted && (
              <Author key={item.id || item.name}>
                <Text>{item.name}</Text>
                {item.imageUrl && <img src={item.imageUrl} alt={item.name} />}

                <Button
                  onClick={() => {
                    setOnPopup({ id: item.id, version: item._version });
                  }}
                  size='small'
                  color='red'
                >
                  X
                </Button>
              </Author>
            )
        )}

        <PopupWarning
          onPopup={onPopup}
          handleClosePopup={handleClosePopup}
          onDelete={handleDeleteAuthor}
        />
      </AuthorsContainer>
      {showCreateAuthor && (
        <CreateContainer>
          <Button
            onClick={() => setShowCreateAuthor(false)}
            size='small'
            color='red'
          >
            X
          </Button>
          <TextField
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder='Author name'
            value={formData.name}
            isRequired
          />
          <div>
            <p>Author avatar</p>
            {imageUrl && (
              <img src={imageUrl} alt='preview' width={50} height={50} />
            )}
            <input
              type='file'
              accept='image/*'
              onChange={handleImageChange}
              label='Author avatar'
            />
          </div>

          <Button onClick={handleCreateAuthor} variation='primary'>
            Create author
          </Button>
        </CreateContainer>
      )}
    </Container>
  );
}

export default AuthorBlock;
