import { Button, Heading } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

const Container = styled('main')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  text-align: center;
  position: relative;
`;
const Header = styled.header`
  padding: 20px;
  display: flex;
  position: relative;
  align-items: center;
  background: aliceblue;
  button {
    flex: 0 0 100px;
  }
  h1 {
    flex: 1;
    text-align: center;
  }
`;
function Layout({ children, signOut }) {
  return (
    <>
      <Header>
        <Link to='/' className='amplify-button amplify-button--link'>
          Home
        </Link>
        <Heading level={1}>Content Management System</Heading>
        <Button onClick={signOut} size='small'>
          Sign Out
        </Button>
      </Header>
      <Container>{children}</Container>
    </>
  );
}

export default Layout;
