import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Heading } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import PopupWarning from '../components/PopupWarning';
import useContent from '../utils/useContent';
import StartCards from '../components/StartCards';
import CreateCard from '../components/CreateCard';
import AuthorBlock from '../components/AuthorBlock';

/// CSS
const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;
const CreateContainer = styled.section`
  display: flex;
  flex-direction: column;
  > button {
    width: fit-content;
  }
`;
const CreatBlock = styled.section`
  display: flex;
  flex-wrap: wrap;
`;
const BlogsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-flow: wrap row;
  margin: 50px 0px;
`;

/// JS
function Main() {
  // fetch data
  const {
    content: weddings,
    formData: formDataBlogs,
    onPopup: onPopupBlogs,
    setOnPopup: setOnPopupBlogs,
    onChange: onChangeBlogs,
    createContent: createBlog,
    deleteContent: deleteBlog,
    setFormData: setFormDataBlogs,
  } = useContent('weddings');

  const {
    content: corporate,
    formData: formDataNews,
    onPopup: onPopupNews,
    setOnPopup: setOnPopupNews,
    onChange: onChangeNews,
    createContent: createNews,
    deleteContent: deleteNews,
    setFormData: setFormDataNews,
  } = useContent('corporate');

  const {
    content: privateItem,
    formData: formDataInterviews,
    onPopup: onPopupInterviews,
    setOnPopup: setOnPopupInterviews,
    onChange: onChangeInterviews,
    createContent: createInterviews,
    deleteContent: deleteInterviews,
    setFormData: setFormDataInterviews,
  } = useContent('private');

  const {
    content: nonProfit,
    formData: formDataGuides,
    onPopup: onPopupGuides,
    setOnPopup: setOnPopupGuides,
    onChange: onChangeGuides,
    createContent: createGuides,
    deleteContent: deleteGuides,
    setFormData: setFormDataGuides,
  } = useContent('nonProfit');

  const [showCreateBlock, setShowCreateBlock] = useState(false);
  const handleClosePopup = () => {
    setOnPopupBlogs('');
  };

  const handleClosePopupNews = () => {
    setOnPopupNews('');
  };

  const handleClosePopupInterviews = () => {
    setOnPopupInterviews('');
  };
  const handleClosePopupGuides = () => {
    setOnPopupGuides('');
  };

  return (
    <>
      <AuthorBlock />
      <BlogsContainer>
        <StartCards
          content={corporate}
          setOnPopup={setOnPopupNews}
          href={'corporate'}
        />
        <StartCards
          content={privateItem}
          setOnPopup={setOnPopupInterviews}
          href={'private'}
        />
        <StartCards
          content={weddings}
          setOnPopup={setOnPopupBlogs}
          href={'weddings'}
        />

        <StartCards
          content={nonProfit}
          setOnPopup={setOnPopupGuides}
          href={'nonProfit'}
        />

        <PopupWarning
          onPopup={onPopupBlogs}
          handleClosePopup={handleClosePopup}
          onDelete={deleteBlog}
        />
        <PopupWarning
          onPopup={onPopupNews}
          handleClosePopup={handleClosePopupNews}
          onDelete={deleteNews}
        />
        <PopupWarning
          onPopup={onPopupInterviews}
          handleClosePopup={handleClosePopupInterviews}
          onDelete={deleteInterviews}
        />
        <PopupWarning
          onPopup={onPopupGuides}
          handleClosePopup={handleClosePopupGuides}
          onDelete={deleteGuides}
        />
      </BlogsContainer>
      <CreateContainer>
        <Button
          variation="link"
          size="small"
          onClick={() => setShowCreateBlock(!showCreateBlock)}
        >
          Show create block
        </Button>
        {showCreateBlock && (
          <CreatBlock>
            <CreateCard
              createSection={createBlog}
              onChangeImage={onChangeBlogs}
              formData={formDataBlogs}
              setFormData={setFormDataBlogs}
              sectionName={'weddings'}
            />
            <CreateCard
              createSection={createNews}
              onChangeImage={onChangeNews}
              formData={formDataNews}
              setFormData={setFormDataNews}
              sectionName={'corporate'}
            />
            <CreateCard
              createSection={createInterviews}
              onChangeImage={onChangeInterviews}
              formData={formDataInterviews}
              setFormData={setFormDataInterviews}
              sectionName={'private'}
            />
            <CreateCard
              createSection={createGuides}
              onChangeImage={onChangeGuides}
              formData={formDataGuides}
              setFormData={setFormDataGuides}
              sectionName={'nonProfit'}
            />
          </CreatBlock>
        )}
      </CreateContainer>
    </>
  );
}

export default Main;
