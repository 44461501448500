import React from 'react';
import { TextField, Button, TextAreaField } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';

const FlexBlock = styled.div`
  display:flex;
  flex-direction:column;
  >button {
    align-self:flex-end;
  }
`
export default function FaqBlock({ value, onChange }) {
  const generateUniqueId = () => new Date().getTime().toString();
  const handleChange = (index, field, fieldValue) => {
    const updatedItems = [...value];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: fieldValue,
    };
    onChange(updatedItems);
  };

  const handleAddItem = () => {
    const newItem = {
      id: generateUniqueId(),
      question: '',
      answer: '',
    };
    const updatedItems = [...value, newItem];
    onChange(updatedItems);
  };

  const handleRemoveItem = (id) => {
    const updatedItems = value.filter((item) => item.id !== id);
    onChange(updatedItems);
  };

  return (
    <div>
      {value.map((item, index) => (
        <FlexBlock
          key={index}
        >
          <TextField
            type='text'
            onChange={(event) =>
              handleChange(index, 'question', event.target.value)
            }
            name={`question-${index}`}
            placeholder='Question'
            label={`Question ${index + 1}`}
            value={item.question}
          />
          <TextAreaField
            onChange={(event) =>
              handleChange(index, 'answer', event.target.value)
            }
            name={`answer-${index}`}
            placeholder='Answer'
            label={`Answer ${index + 1}`}
            value={item.answer}
          />

          {value.length > 0 && (
            <Button
              onClick={() => handleRemoveItem(item.id)}
              variation='danger'
              size='small' color='red'
            >
              Remove
            </Button>
          )}
        </FlexBlock>
      ))}
      <Button onClick={handleAddItem}>Add FAQ</Button>
    </div>
  );
}
