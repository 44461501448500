import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';
import styled from '@emotion/styled';
import { Button, Heading } from '@aws-amplify/ui-react';
import PostForm from '../components/PostForm';
import PopupWarning from '../components/PopupWarning';
import { getImageUrl } from '../utils';

const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  & > button {
    margin-left: auto;
  }
  h2 {
    text-align: center;
  }
`;

// If featuredImage was changed but edit post was not submitted all new featured images should
// be removed from storage ??
// If post edit was submitted with the new featuredImage, all previous images should be removed ??
// The Images flow is buggy, we should brainstorm the flow

function ChangeArticle({
  getQuery,
  getQueryTitle,
  deleteMutation,
  deleteMutationTitle,
  getUpdatedData,
  idName,
  updateMutation,
  route = '/blog/',
}) {
  const [post, setPost] = useState();
  const { postID: id } = useParams();
  const navigate = useNavigate();
  const [onPopup, setOnPopup] = useState('');
  const handleClosePopup = () => {
    setOnPopup('');
  };
  const [imagesToDelete, setImagesToDelete] = React.useState([]);
  async function onGetPost() {
    const apiData = await API.graphql({ query: getQuery, variables: { id } });

    let featuredImageUrl;
    let mediaUrls;
    if (apiData.data?.[getQueryTitle]?.featuredImage) {
      featuredImageUrl = getImageUrl(
        apiData.data?.[getQueryTitle]?.featuredImage,
      );
    }
    if (apiData.data?.[getQueryTitle]?.media.length > 0) {
      mediaUrls = apiData.data?.[getQueryTitle]?.media.map(getImageUrl);
    }
    setPost({ ...apiData.data[getQueryTitle], featuredImageUrl, mediaUrls });
  }
  useEffect(() => {
    onGetPost();
  }, []);

  async function onFeaturedImageChange(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const fileName = `${post.id}-${file.name}`;
    setImagesToDelete([...imagesToDelete, post.featuredImage]);
    if (post) {
      setPost({
        ...post,
        featuredImage: fileName,
        featuredImageUrl: getImageUrl(file.name),
      });
    }
    await Storage.put(fileName, file);
  }

  async function onImageChange(e) {
    if (!e.target.files[0]) return;
    const { files } = e.target;
    const filesArr = [...files];
    if (post) {
      const fileNames = filesArr.map(file => `${post.id}-${file.name}`);
      const postMedia = fileNames.reduce((media, fName) => {
        if (!media.includes(fName)) {
          media.push(fName);
        }
        return media;
      }, post.media);
      const postMediaUrls = postMedia.map(getImageUrl);
      await Promise.all(
        filesArr.map(async file => {
          await Storage.put(`${post.id}-${file.name}`, file);
        }),
      );
      setPost({ ...post, media: postMedia, mediaUrls: postMediaUrls });
    }
  }

  function deleteImage(fileName) {
    const filteredMedia = post.media.filter(name => name !== fileName);
    setImagesToDelete([...imagesToDelete, fileName]);
    setPost({
      ...post,
      media: filteredMedia,
    });
  }

  async function updateImagesInStorage(submit) {
    if (submit && imagesToDelete.length > 0) {
      await Promise.all(
        imagesToDelete.map(async fileName => {
          await Storage.remove(fileName);
        }),
      );
      setImagesToDelete([]);
    }
  }

  async function deletePost(version) {
    const { data } = await API.graphql({
      query: deleteMutation,
      variables: { input: { id, _version: version } },
    });
    if (data?.[deleteMutationTitle]?.featuredImage) {
      await Storage.remove(data?.[deleteMutationTitle]?.featuredImage);
    }
    if (data?.[deleteMutationTitle]?.media?.length > 0) {
      data?.[deleteMutationTitle]?.media.forEach(key => Storage.remove(key));
    }
    navigate(-1);
  }
  async function updatePost(values) {
    const isUpdatePost = getUpdatedData(values, post);

    if (!values.title) return;
    await API.graphql({
      query: updateMutation,
      variables: { input: { id, ...isUpdatePost } },
    });
    await updateImagesInStorage();

    navigate(-1);
  }

  return (
    <Container>
      <Link
        to={`${route}${post?.[idName]}`}
        style={{
          position: 'absolute',
          top: '50px',
        }}
        className="amplify-button amplify-button--link"
      >
        Back
      </Link>
      <Heading level={2}>Edit page</Heading>
      <Button size="small" color="red" onClick={() => setOnPopup(id)}>
        Delete
      </Button>
      {post && (
        <PostForm
          onSubmit={updatePost}
          onFeaturedImageChange={onFeaturedImageChange}
          id={id}
          initialValues={post}
          onImageChange={onImageChange}
          onImageDelete={deleteImage}
        />
      )}

      <PopupWarning
        onPopup={onPopup}
        handleClosePopup={handleClosePopup}
        onDelete={() => deletePost(post._version)}
      />
    </Container>
  );
}

export default ChangeArticle;
