export const initialFormState = {
  title: '',
  content: '',
  visibility: true,
  publishAt: new Date(new Date().getTime()),
  featuredImage: undefined,
  featuredImageAlt: '',
  photoCredit: '',
  author: '',
  avatar: '',
  exerpt: '',
  seoTitle: '',
  seoDescription: '',
  handle: '',
  duration: 0,
  media: [],
  location: '',
  categories: [],
  faq: [],
  problem: [],
};
export const initialFormStateNews = {
  title: '',
  articlecontent: '',
  visibility: true,
  publishAt: new Date(new Date().getTime()),
  featuredImage: undefined,
  featuredImageAlt: '',
  photoCredit: '',
  author: '',
  avatar: '',
  exerpt: '',
  seoTitle: '',
  seoDescription: '',
  handle: '',
  duration: 0,
  media: [],
  location: '',
  categories: [],
  problem: [],
};
export const initialFormStateInterviews = {
  title: '',
  content: '',
  visibility: true,
  publishAt: new Date(new Date().getTime()),
  featuredImage: undefined,
  featuredImageAlt: '',
  photoCredit: '',
  author: '',
  avatar: '',
  exerpt: '',
  seoTitle: '',
  seoDescription: '',
  handle: '',
  duration: 0,
  media: [],
  location: '',
  categories: [],
  problem: [],
};
export const initialFormStateGuides = {
  title: '',
  content: '',
  visibility: true,
  publishAt: new Date(new Date().getTime()),
  featuredImage: undefined,
  featuredImageAlt: '',
  photoCredit: '',
  author: '',
  avatar: '',
  exerpt: '',
  seoTitle: '',
  seoDescription: '',
  handle: '',
  duration: 0,
  media: [],
  location: '',
  categories: [],
  faq: [],
  problem: [],
};
export const getUpdatedData = (values, post) => ({
  title: values.title,
  content: values.maincontent,
  visibility: values.visibility,
  publishAt: values.publishAt,
  featuredImage: post.featuredImage,
  featuredImageAlt: values.featuredImageAlt,
  photoCredit: values.photoCredit,
  author: values.author,
  avatar: values.avatar,
  exerpt: values.exerpt,
  categories: values.categories,
  seoTitle: values.seoTitle,
  seoDescription: values.seoDescription,
  handle: values.handle,
  duration: values.duration,
  media: post.media,
  faq: values.faq,
  problem: values.problem,
  location: values.location,
  _version: post._version,
});
export const getUpdatedDataNews = (values, post) => ({
  title: values.title,
  content: values.articlecontent,
  visibility: values.visibility,
  publishAt: values.publishAt,
  featuredImage: post.featuredImage,
  featuredImageAlt: values.featuredImageAlt,
  photoCredit: values.photoCredit,
  author: values.author,
  avatar: values.avatar,
  exerpt: values.exerpt,
  categories: values.categories,
  seoTitle: values.seoTitle,
  seoDescription: values.seoDescription,
  handle: values.handle,
  duration: values.duration,
  media: post.media,
  problem: values.problem,
  location: values.location,
  _version: post._version,
});
export const getUpdatedDataInterview = (values, post) => ({
  title: values.title,
  content: values.interviewcontent,
  visibility: values.visibility,
  publishAt: values.publishAt,
  featuredImage: post.featuredImage,
  featuredImageAlt: values.featuredImageAlt,
  photoCredit: values.photoCredit,
  author: values.author,
  avatar: values.avatar,
  exerpt: values.exerpt,
  categories: values.categories,
  seoTitle: values.seoTitle,
  seoDescription: values.seoDescription,
  handle: values.handle,
  duration: values.duration,
  problem: values.problem,
  location: values.location,
  media: post.media,
  _version: post._version,
});
export const getUpdatedDataGuides = (values, post) => ({
  title: values.title,
  content: values.guidecontent,
  visibility: values.visibility,
  publishAt: values.publishAt,
  featuredImage: post.featuredImage,
  featuredImageAlt: values.featuredImageAlt,
  photoCredit: values.photoCredit,
  author: values.author,
  avatar: values.avatar,
  exerpt: values.exerpt,
  categories: values.categories,
  seoTitle: values.seoTitle,
  seoDescription: values.seoDescription,
  handle: values.handle,
  duration: values.duration,
  media: post.media,
  problem: values.problem,
  location: values.location,
  faq: values.faq,
  _version: post._version,
});
